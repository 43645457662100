// Generated by Framer (2e3161d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/iWdmJKb6h";
import * as sharedStyle from "../css/xV5i52TGE";

const serializationHash = "framer-5cgFq"

const variantClassNames = {lOROOx7yt: "framer-v-ywri0y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, DlVkDJ9da: link ?? props.DlVkDJ9da, kekLjVft2: text ?? props.kekLjVft2 ?? "Artikelstammdaten optimal verwalten: Tipps & Strategien"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DlVkDJ9da, kekLjVft2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "lOROOx7yt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ywri0y", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lOROOx7yt"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1x8tv2m"} data-styles-preset={"xV5i52TGE"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-b9ffd317-0d5a-43e0-bab1-6bf465168afd, rgb(64, 120, 150)))"}}><Link href={DlVkDJ9da} nodeId={"r717ZZxBK"} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-n8wp4s"} data-styles-preset={"iWdmJKb6h"}>Artikelstammdaten optimal verwalten: Tipps &amp; Strategien</motion.a></Link></motion.p></React.Fragment>} className={"framer-dt991i"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"r717ZZxBK"} style={{"--extracted-r6o4lv": "var(--token-b9ffd317-0d5a-43e0-bab1-6bf465168afd, rgb(64, 120, 150))"}} text={kekLjVft2} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5cgFq.framer-wmqv0h, .framer-5cgFq .framer-wmqv0h { display: block; }", ".framer-5cgFq.framer-ywri0y { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 42px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 280px; }", ".framer-5cgFq .framer-dt991i { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 280px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5cgFq.framer-ywri0y { gap: 0px; } .framer-5cgFq.framer-ywri0y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5cgFq.framer-ywri0y > :first-child { margin-left: 0px; } .framer-5cgFq.framer-ywri0y > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DlVkDJ9da":"link","kekLjVft2":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerxt8uaY1eu: React.ComponentType<Props> = withCSS(Component, css, "framer-5cgFq") as typeof Component;
export default Framerxt8uaY1eu;

Framerxt8uaY1eu.displayName = "Link";

Framerxt8uaY1eu.defaultProps = {height: 42, width: 280};

addPropertyControls(Framerxt8uaY1eu, {DlVkDJ9da: {title: "Link", type: ControlType.Link}, kekLjVft2: {defaultValue: "Artikelstammdaten optimal verwalten: Tipps & Strategien", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(Framerxt8uaY1eu, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})